/* 페이지에서 이렇게 선언하면 로그인만 접근하게
definePageMeta({
  middleware: 'auth'
})
*/
import { useMemberSessionStore } from 'share/stores/memberSessionStore'

export default defineNuxtRouteMiddleware((to, from) => {
  console.log('auth middleware', to)

  const { isLoggedIn } = useMemberSessionStore()

  if (!isLoggedIn) {
    return navigateTo(`/auth/sign-in?redirectUrl=${to.fullPath}`)
  }
})
